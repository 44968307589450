import React from "react";
import {graphql} from 'gatsby';
import FilterPage from "../components/FilterPage";
import Seo from "../components/Seo";


export default function Blog(props) {
  return (
    <FilterPage
      data={props.data}
      location={props.location}
      title='Blog'
      description='Updates on course catalog software, curriculum management, syllabus management, and more.'


    />
  )
}

export function Head(props) {
  return (
    <Seo
      title='Blog'
      description='Updates on course catalog software, curriculum management, syllabus management, and more.'
      canonical={props.location.pathname}
    />
  )
}


export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {published: {ne: false}, type: {eq: "blog"}}}) {
      edges {
        node {
          frontmatter {
            tags
            title
            path
            date
            lastupdated
          }
          fields {
            slug
          }
          excerpt
          html
        }
      }
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
  },
`;
